import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/Home';
import SeeMore from './pages/SeeMore';
import SeeMoreCollection from './pages/SeeMoreCollection';
import Profile from './pages/Profile';
import Catalog from './pages/Catalog';
import Category from './pages/Category';
import Categories from './pages/Categories';
import Admin from './pages/Admin';
import ProductDetails from './pages/ProductDetails';
import OrderCheckout from './pages/OrderCheckout';
import OrderConfirmation from './pages/OrderConfirmation';
import Orders from './pages/Orders';
import ComingSoon from './pages/ComingSoon';
import Checkout from './pages/Checkout';
import Confirmation from './pages/Confirmation';
import OrderManagement from './pages/OrderManagement';
import AdminOrderView from './pages/AdminOrderView';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Shipping from './pages/Shipping';
import Returns from './pages/Returns';
import FAQ from './pages/FAQ';
import About from './pages/About';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import WhatsAppButton from './components/WhatsAppButton';

// Componente AppContent que utiliza useLocation (debe estar dentro del Router)
function AppContent() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isComingSoonRoute = location.pathname === '/coming-soon';

  return (
    <div className="min-h-screen bg-gray-50">
      {!isAdminRoute && !isComingSoonRoute && <Navbar />}
      <main>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/category/:slug" element={<Category />} />
          <Route path="/category/:slug/:subSlug" element={<Category />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/see-more/:id" element={<SeeMoreCollection />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/returns" element={<Returns />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/coming-soon" element={<ComingSoon />} />

          {/* Rutas de autenticación (redirigen a home si el usuario está autenticado) */}
          <Route 
            path="/login" 
            element={
              <ProtectedRoute requireAuth={false}>
                <Login />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/register" 
            element={
              <ProtectedRoute requireAuth={false}>
                <Register />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/forgot-password" 
            element={
              <ProtectedRoute requireAuth={false}>
                <ForgotPassword />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/reset-password" 
            element={
              <ProtectedRoute requireAuth={false}>
                <ResetPassword />
              </ProtectedRoute>
            } 
          />

          {/* Rutas protegidas (requieren autenticación) */}
          <Route 
            path="/profile/*" 
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            } 
          />
          
          {/* Ruta específica para el detalle del pedido */}
          <Route 
            path="/profile/order/:id" 
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/orders" 
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/checkout" 
            element={
              <ProtectedRoute>
                <OrderCheckout />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/order/:id/payment" 
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/order/:id/confirmation" 
            element={
              <ProtectedRoute>
                <OrderConfirmation />
              </ProtectedRoute>
            } 
          />

          {/* Rutas de administrador */}
          <Route 
            path="/admin/*" 
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin/order/:id" 
            element={
              <ProtectedRoute>
                <AdminOrderView />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </main>
      {!isAdminRoute && !isComingSoonRoute && <Footer />}
      {!isAdminRoute && <WhatsAppButton />}
    </div>
  );
}

// Componente principal App
export default function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </HelmetProvider>
  );
}